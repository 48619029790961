import React, { FC, useState, useEffect } from 'react';
import {
  InputLabel,
  InputBase,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import { pathOr } from 'ramda';
import { ChevDown } from 'assets/svgs';
import { useStyles } from './Select.styles';
import { SelectProps } from './Select.props';

const SelectView: FC<SelectProps> = (props: SelectProps) => {
  const {
    id,
    onChange,
    value,
    label,
    labelWidth,
    placeholder,
    options,
    outlined,
    customContent = false,
    children,
    openOverride = false,
    setIsOpenOverride = () => {},
  } = props;

  const classes = useStyles(props);
  const SelectInput = outlined ? OutlinedInput : InputBase;

  const [isOpen, setIsOpen] = useState(openOverride);

  useEffect(() => {
    setIsOpen(openOverride);
  }, [openOverride]);

  useEffect(() => {
    setIsOpenOverride(isOpen);
  }, [isOpen]);

  return (
    <FormControl
      style={customContent ? { width: '95%' } : { width: 'calc(100% - 1em' }}
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        value={value}
        onChange={event => onChange(pathOr('', ['target', 'value'], event))}
        input={
          <SelectInput labelWidth={labelWidth || 0} name={'input'} id={id} />
        }
        IconComponent={ChevDown}
      >
        {placeholder && (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem
            style={customContent ? { display: 'none' } : {}}
            key={option.label}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
        {customContent && children}
      </Select>
    </FormControl>
  );
};

export default React.memo(SelectView);
