import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { UpdatePasswordState } from 'types/store/UpdatePasswordState';
import View from './UpdatePasswordForm.view';
import { UpdatePasswordFormPublicProps } from './UpdatePasswordForm.props';
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import authThunks from '../../../thunks/auth';

const FieldContainer: FC<UpdatePasswordFormPublicProps> = (
  props: UpdatePasswordFormPublicProps,
) => {
  const dispatch = useDispatch();
  const updatePasswordState = useSelectorSafe<UpdatePasswordState>(
    // @ts-ignore
    store => store.updatePassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  const openPasswordChanged = props.openModal
    ? props.openModal(LoginModalForms.UPDATE_PASSWORD_CHANGED)
    : () => {};

  const {
    state,
    handleOnSubmit,
    handleOnChange,
    disable,
    handleOnBlur,
  } = useForm(
    {
      newPassword: { value: '', error: '' },
      verifyNewPassword: { value: '', error: '' },
      currentPassword: { value: '', error: '' },
    },
    {
      newPassword: (value?: string) =>
        !value ? 'Password is required.' : null,
      currentPassword: (value?: string) =>
        !value ? 'Current password is required.' : null,
      // @ts-ignore
      verifyNewPassword: (value?: string, s) => {
        if (!value) {
          return 'Confirm password is requried.';
        }
        if (s.newPassword.value !== value) {
          return 'Confirm password and password must match.';
        }

        return null;
      },
    },
    (s: any) => {
      dispatch(
        authThunks.updatePassword(
          {
            currentPassword: s.currentPassword.value,
            newPassword: s.newPassword.value,
          },
          {
            finishUpdating: () => {
              openPasswordChanged();
            },
            updateErrorMessage: (err: any) => {},
          },
        ),
      );
    },
  );

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  return (
    <View
      {...props}
      onClickCreateNewPassword={handleOnSubmit}
      disabled={disable}
      newPassword={state.newPassword}
      verifyNewPassword={state.verifyNewPassword}
      setNewPassword={handleOnChange('newPassword')}
      setVerifyNewPassword={handleOnChange('verifyNewPassword')}
      setTouchedNewPassword={handleOnBlur('newPassword')}
      setTouchedVerifyNewPassword={handleOnBlur('verifyNewPassword')}
      currentPassword={state.currentPassword}
      setCurrentPassword={handleOnChange('currentPassword')}
      setTouchedCurrentPassword={handleOnBlur('currentPassword')}
      errors={errors}
      error={updatePasswordState.status === 'ERROR'}
    />
  );
};

export default React.memo(FieldContainer);
