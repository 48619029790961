import { Overrides } from '@material-ui/core/styles/overrides';
import MuiContainer from './MuiContainer';
import MuiBackdrop from './MuiBackdrop';
import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiSlider from './MuiSlider';
import MuiCheckbox from './MuiCheckbox';
import MuiSelect from './MuiSelect';
import MuiInputBase from './MuiInputBase';
import MuiPaper from './MuiPaper';

// Look at the material UI docs for examples of how to override their components.
const overrides: Overrides = {
  MuiContainer,
  MuiBackdrop,
  MuiIconButton,
  MuiButton,
  MuiSlider,
  MuiCheckbox,
  MuiSelect,
  MuiInputBase,
  MuiPaper,
};

export default overrides;
