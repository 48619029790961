import React, { FC } from 'react';
// @ts-ignore
import StarRatings from 'react-star-ratings';
import { colors } from 'themeConfig/themeConstants';
import { HeartIcon } from 'assets/svgs';
import { useStyles } from './LocationCard.styles';
import { LocationCardProps } from './LocationCard.props';
import Typo from '../Typo';

const LocationCardView: FC<LocationCardProps> = props => {
  const classes = useStyles(props);
  const { isLoggedIn } = props;
  return (
    <div
      className={classes.container}
      onClick={props.onClick}
      onMouseOver={props.onMouseOver ? props.onMouseOver : () => {}}
      onMouseOut={props.onMouseOut ? props.onMouseOut : () => {}}
    >
      <div className={classes.imageContainer}>
        <div className={classes.image} />
        {isLoggedIn && (
          <HeartIcon
            className={classes.heart}
            onClick={(event: any) => {
              event.stopPropagation();
              props.onFavouriteClick();
            }}
          />
        )}
      </div>
      <div className={classes.infoContainer}>
        <Typo variant="h6" color="blue">
          {props.type}
        </Typo>
        <Typo variant="h6" color="blue">
          Capacity: {props.capacity}
        </Typo>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '115px',
          flex: 1,
        }}
      >
        <div
          style={{
            flex: 1.75,
            display: 'flex',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typo variant="h3" color="darkGrey" className={classes.title}>
            {props.title}
          </Typo>
        </div>
        <div
          style={{
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typo variant="body1" color="lightGrey" className={classes.address}>
              {props.address}
            </Typo>
          </div>
          <div
            className={classes.bottomContainer}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <StarRatings
              rating={props.rating}
              starRatedColor={colors.red}
              starHoverColor={colors.red}
              starEmptyColor={colors.lightGrey}
              numberOfStars={5}
              svgIconPath="M7.5 11.25 3.09161061 13.5676275 3.93353806 8.65881373 0.367076128 5.18237254 5.2958053 4.46618627 7.5 0 9.7041947 4.46618627 14.6329239 5.18237254 11.0664619 8.65881373 11.9083894 13.5676275z"
              svgIconViewBox="0 0 15 14"
              starDimension="15px"
              starSpacing="0px"
              name="rating"
            />
            <Typo variant="h5" color="darkGrey" className={classes.price}>
              ${props.weekdayPrice} - ${props.weekendPrice} /hour
            </Typo>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LocationCardView);
