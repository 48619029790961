import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './CheckYourEmailDialog.styles';
import { CheckYourEmailDialogProps } from './CheckYourEmailDialog.props';
import { Typo } from '../../primitives';

const getMessage = (action: string) => {
  if (action === 'verify-email') {
    return {
      title: 'Verify Email Address',
      message: `We‘ve sent you a link to verify your email address. Please check your junk or spam folders.`,
      action: 'verify-email',
    };
  }

  return {
    title: 'Reset Password',
    message: `We‘ve sent you a link to reset your password. Please check your junk or spam folders.`,
    action: 'reset-password',
  };
};

const ResetPasswordFormView: FC<CheckYourEmailDialogProps> = props => {
  const classes = useStyles();
  const messages = getMessage(props.params.action);

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        {messages.title}
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        {messages.message}
      </Typo>
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Didn&lsquo;t receive it?&nbsp;
          <Typo
            variant="body1"
            color="blue"
            component="button"
            className={classes.sendAgain}
            onClick={
              messages.action === 'reset-password'
                ? props.resendForgotPasswordEmail
                : props.resendVerifyEmail
            }
          >
            Send again
          </Typo>
        </Typo>
      </Box>
      <Box className={classes.footer}>
        {Boolean(props.message.message) && (
          <Typo
            variant="body1"
            color={props.message.type === 'success' ? 'green' : 'red'}
          >
            {props.message.message}
          </Typo>
        )}
      </Box>
    </>
  );
};

export default React.memo(ResetPasswordFormView);
