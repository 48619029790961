import { useState, useEffect, useCallback } from 'react';
import mergeDeepLeft from 'ramda/es/mergeDeepLeft';
import mapObjIndexed from 'ramda/es/mapObjIndexed';

// TODO: proper typing

const addTouchedTrue = (state: any) =>
  mapObjIndexed<any, any>(value => ({ ...value, touched: true }), state);

// @ts-ignore
function useForm(stateSchema, validationSchema, callback, serverError?) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(
    (isSubmit?: boolean) => {
      if (isSubmit) {
        setState(addTouchedTrue);
      }

      const hasErrorInState = Object.keys(validationSchema).some(key => {
        const stateError = state[key].error; // state error

        return stateError;
      });

      return hasErrorInState;
    },
    [state, validationSchema],
  );

  // Disable button in initial render.
  useEffect(() => {
    setDisable(true);
  }, []);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
  }, [state, isDirty]);

  useEffect(() => {
    setState((s: any) => {
      return mergeDeepLeft(addTouchedTrue(serverError), s);
    });
  }, [serverError]);

  // Used to handle every changes in every input
  const handleOnChange = useCallback(
    name => (value: any) => {
      setIsDirty(true);

      if (validationSchema[name]) {
        // @ts-ignore
        return setState(prevState => ({
          ...prevState,
          [name]: {
            value,
            error: validationSchema[name](value, state),
          },
        }));
      }

      return setState((prevState: any) => ({
        ...prevState,
        [name]: {
          value,
          error: '',
        },
      }));
    },
    [validationSchema],
  );

  const handleOnSubmit = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault();
      }

      // Make sure that validateState returns false
      // Before calling the submit callback function
      if (!validateState(true)) {
        callback(state);
      }
    },
    [state],
  );

  const handleOnBlur = (name: string) => () => {
    setState((s: any) => ({
      ...s,
      [name]: { ...s[name], touched: true },
    }));
  };

  const reset = () => {
    setDisable(true);
    setState(stateSchema);
  };

  return {
    state,
    disable,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
    reset,
  };
}

export default useForm;
