export const SET_BOOKING = 'SET_BOOKING';
export const SET_BOOKING_QUESTIONS = 'SET_BOOKING_QUESTIONS';
export const SET_BOOKING_STATUS = 'SET_BOOKING_STATUS';
export const CLEAR_BOOKING = 'CLEAR_BOOKING';

const bookingDetailActions = {
  setBooking: (booking: {
    listingId: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    hostName: string;
    location: any;
    pricePerHour: string;
    weekdayPricePerHour?: string;
    weekendPricePerHour?: string;
    weekdayBookingHours?: any;
    weekendBookingHours?: any;
    venueName: string;
    description: string;
    capacity: string;
    heroImage: string;
    host: any;
  }) => ({ type: SET_BOOKING, booking }),
  setBookingQuestions: (
    questions: {
      id: string;
      question: string;
      answer: string;
    }[],
  ) => ({ type: SET_BOOKING_QUESTIONS, questions }),
  setBookingStatus: (bookingStatus: string) => ({
    type: SET_BOOKING_STATUS,
    bookingStatus,
  }),
  clearBooking: () => ({ type: CLEAR_BOOKING }),
};

export default bookingDetailActions;
