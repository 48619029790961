import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './Widget.styles';
import { WidgetProps } from './Widget.props';
import Typo from '../Typo';

const WidgetView: FC<WidgetProps> = ({ heading, bodyChild }: WidgetProps) => {
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <Typo variant="h3" className={classes.field}>
          {heading}
        </Typo>
      </Box>
      <Box>{bodyChild}</Box>
    </Box>
  );
};

export default React.memo(WidgetView);
