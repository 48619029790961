import React, { createContext, useState } from 'react';

const ModalContext = createContext({
  open: '',
  setModal: (name: string) => {},
  onClose: () => {},
});

// @ts-ignore
export const ModalProvider = ({ children }) => {
  const [state, setState] = useState({
    open: 'CLOSE',
    onClose: () => {},
  });
  const setModal = (name: string) =>
    setState({
      ...state,
      open: name,
    });
  return (
    <ModalContext.Provider
      value={{
        ...state,
        setModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;
