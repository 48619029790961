import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { Contact } from 'types/Contact';
import { ContactState } from 'types/store/ContactState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions, contactActions } from '../actions';

const DEFAULT_STATE: ContactState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<ContactState, any>(
  {
    ...createAsyncReducers<Contact>('CONTACT/SUBMIT'),
    [String(contactActions.clear)]: () => DEFAULT_STATE,
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
