import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingBottom: '40px',
      position: 'relative',
    },
    closeIcon: {
      width: '20px',
      height: '20px',
      position: 'absolute',
      right: '20px',
      top: '20px',
      cursor: 'pointer',
    },
    closeIconSmall: {
      width: '20px',
      height: '20px',
      justifySelf: 'flex-end',
      cursor: 'pointer',
    },
    titleContainer: {
      padding: '10px 15px',
      minHeight: '50px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid rgba(0,0,0,0.05)',
        marginBottom: '30px',
      },
    },
    paper: {
      boxShadow: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      borderRadius: '5px',
    },
    appBar: {
      height: '60px',
      backgroundColor: 'white',
      boxShadow: '0 1px 1px rgba(0,0,0,0.02)',
    },
    spacer: {
      flexGrow: 1,
    },
  }),
);
