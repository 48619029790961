import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ResetPasswordState } from 'types/store/ResetPasswordState';
import View from './CreateNewPasswordForm.view';
import { CreateNewPasswordFormPublicProps } from './CreateNewPasswordForm.props';
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import useSelectorSafe from '../../../store/selectors/useSelectorSafe';
import authThunks from '../../../thunks/auth';

const FieldContainer: FC<CreateNewPasswordFormPublicProps> = (
  props: CreateNewPasswordFormPublicProps,
) => {
  const dispatch = useDispatch();
  const query = useSelectorSafe(state => state.location.query);
  const resetPasswordState = useSelectorSafe<ResetPasswordState>(
    // @ts-ignore
    store => store.resetPassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  // @ts-ignore
  const resetPassword = (query || {})['reset-password'] || '';

  const openPasswordChanged = props.openModal
    ? props.openModal(LoginModalForms.PASSWORD_CHANGED)
    : () => {};

  useEffect(() => {
    if (resetPasswordState.status === 'COMPLETE' && props.openModal) {
      openPasswordChanged();
    }
  }, [resetPasswordState.status]);

  const {
    state,
    handleOnSubmit,
    handleOnChange,
    disable,
    handleOnBlur,
  } = useForm(
    {
      newPassword: { value: '', error: '' },
      verifyNewPassword: { value: '', error: '' },
    },
    {
      newPassword: (value?: string) =>
        !value ? 'Password is required.' : null,
      // @ts-ignore
      verifyNewPassword: (value?: string, s) => {
        if (!value) {
          return 'Confirm password is requried.';
        }
        if (s.newPassword.value !== value) {
          return 'Confirm password and password must match.';
        }

        return null;
      },
    },
    (s: any) => {
      dispatch(
        authThunks.resetPassword({
          token: resetPassword,
          newPassword: s.newPassword.value,
        }),
      );
    },
  );

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  return (
    <View
      {...props}
      onClickCreateNewPassword={handleOnSubmit}
      disabled={disable}
      newPassword={state.newPassword}
      verifyNewPassword={state.verifyNewPassword}
      setNewPassword={handleOnChange('newPassword')}
      setVerifyNewPassword={handleOnChange('verifyNewPassword')}
      setTouchedNewPassword={handleOnBlur('newPassword')}
      setTouchedVerifyNewPassword={handleOnBlur('verifyNewPassword')}
      errors={errors}
      error={resetPasswordState.status === 'ERROR'}
    />
  );
};

export default React.memo(FieldContainer);
