import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import View from './Modal.view';
import { ModalPublicProps } from './Modal.props';
import { routerActions } from '../../../store/actions';
import ROUTES from '../../../routes';

const ModalContainer: FC<ModalPublicProps> = (props: ModalPublicProps) => {
  const dispatch = useDispatch();
  return (
    <View
      {...props}
      goToHomeRoute={() => dispatch(routerActions.link(ROUTES.ROOT))}
    />
  );
};

export default React.memo(ModalContainer);
