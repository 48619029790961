import React, { FC, ReactElement } from 'react';

// @ts-ignore
import StarRatings from 'react-star-ratings';
import { colors } from 'themeConfig/themeConstants';
import { StarRatingProps } from './StarRating.props';

const StarRatingView: FC<StarRatingProps> = (props): ReactElement<'div'> => {
  return (
    <StarRatings
      rating={props.rating}
      starRatedColor={colors.red}
      starHoverColor={colors.red}
      starEmptyColor={colors.lightGrey}
      numberOfStars={5}
      svgIconPath="M7.5 11.25 3.09161061 13.5676275 3.93353806 8.65881373 0.367076128 5.18237254 5.2958053 4.46618627 7.5 0 9.7041947 4.46618627 14.6329239 5.18237254 11.0664619 8.65881373 11.9083894 13.5676275z"
      svgIconViewBox="0 0 15 14"
      starDimension={props.size || '15px'}
      starSpacing="0px"
      name="rating"
      changeRating={props.onChangeRating}
    />
  );
};

export default StarRatingView;
