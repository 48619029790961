import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';
import { InputFieldProps } from './InputField.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props: InputFieldProps) => ({
      backgroundColor: 'white',
      borderRadius: '5px',
      border:
        (props.errorMessage || '').length > 0 || props.error
          ? `1px solid ${colors.red}`
          : '1px solid rgb(245, 246, 246)',
      color: 'rgb(5, 195, 217)',
      width: '100%',
    }),
    iconContainer: {
      height: '50px',
      width: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      width: '20px',
      height: '20px',
    },
    inputField: {
      width: '100%',
      marginLeft: (props: InputFieldProps) =>
        props.icon ? '0px' : theme.spacing(2),
    },
    input: {
      color: 'rgb(187, 192, 194)',
      minHeight: '50px',
    },
    error: {
      paddingLeft: 2,
      paddingRight: 2,
    },
  }),
);
