import React, { FC } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { useStyles } from './InputField.styles';
import { InputFieldProps } from './InputField.props';

const InputFieldView: FC<InputFieldProps> = (props: InputFieldProps) => {
  const {
    id,
    icon: Icon,
    placeholder,
    style,
    onChange,
    value,
    className,
    type,
    onBlur,
    multiline,
    errorMessage = '',
  } = props;

  const classes = useStyles(props);

  return (
    <Grid
      container
      alignItems="flex-end"
      className={`${classes.container} ${className}`}
      style={style}
    >
      {Icon ? (
        <Grid item className={classes.iconContainer}>
          <Icon className={classes.icon} />
        </Grid>
      ) : null}
      <Grid item xs>
        <TextField
          id={id}
          // error={errorMessage.length > 0 || props.error}
          placeholder={placeholder}
          className={classes.inputField}
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          helperText={errorMessage}
          FormHelperTextProps={{
            classes: {
              root: classes.error,
            },
            error: true,
          }}
          onChange={onChange}
          value={value}
          type={type || 'text'}
          onBlur={onBlur}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(InputFieldView);
