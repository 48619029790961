import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: 'rgb(243, 245, 245)',
      borderRadius: '5px',
      paddingTop: '63.63%',
      position: 'relative',
      backgroundImage: (props: { image: string }) => `url(${props.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    icon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  }),
);
