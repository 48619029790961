import login from './login';
import createAccount from './createAccount';
import facebookAccess from './facebookAccess';
import googleAccess from './googleAccess';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import sendVerificationEmail from './sendVerificationEmail';
import updatePassword from './updatePassword';

const authThunks = {
  login,
  createAccount,
  facebookAccess,
  googleAccess,
  forgotPassword,
  resetPassword,
  sendVerificationEmail,
  updatePassword,
};

export default authThunks;
