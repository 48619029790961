import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { CameraIcon } from 'assets/svgs';
import { useStyles } from './HeaderImage.styles';
import { HeaderImageProps } from './HeaderImage.props';

const HeaderImageView: FC<HeaderImageProps> = ({ image }: HeaderImageProps) => {
  const classes = useStyles({ image: image || '' });
  return (
    <Box className={classes.container}>
      {image ? null : <CameraIcon className={classes.icon} />}
    </Box>
  );
};

export default React.memo(HeaderImageView);
