export const TERMS = {
  title: 'WEBSITE TERMS AND CONDITIONS',
  description: `In these terms and conditions, “we” “us” and “our” refers to Airplacer Pty Ltd. Your access to
and use of all information on this website including purchase of our product/s is provided subject
to the following terms and conditions. The information is intended for residents of Australia
only.
We reserve the right to amend this Notice at any time and your use of the website following any
amendments will represent your agreement to be bound by these terms and conditions as
amended. We therefore recommend that each time you access our website you read these terms
and conditions.`,
  elements: [
    {
      title: 'Members',
      elements: [
        {
          content:
            'In order to access the services provided on this website, you must become a member. You must complete registration by providing certain information as set out on our membership registration page. Please refer to our Privacy Policy linked on our home page for information relating to our collection, storage and use of the details you provide on registration.',
          subElements: [],
        },
        {
          content:
            'You agree to ensure that your registration details are true and accurate at all times and you undertake to update your registration details from time to time when they change.',
          subElements: [],
        },
        {
          content:
            'On registration, we provide you with a password. On registration you agree to pay for our services as set out on our website.',
          subElements: [],
        },
        {
          content:
            'We reserve the right to terminate your membership at any time if you breach these terms and conditions.',
          subElements: [],
        },
        {
          content:
            'Our services are intended to be used by members within Australia only.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Our Website Services',
      elements: [
        {
          content:
            'Our services are provided to adults over the age of eighteen (18) years. By proceeding to purchase through our website, you acknowledge that you are over 18 years of age.',
          subElements: [],
        },
        {
          content:
            'All prices are in Australian Dollars (AUD) and are exclusive of GST. We endeavour to ensure that our price list is current. Our price list can be accessed from our home page and we reserve the right to amend our prices at any time. If you have placed an order, we undertake to fulfil your order at the price listed at the time you ordered.',
          subElements: [],
        },
        {
          content:
            'We strive to ensure that our products are described as accurately as possible on our website, however we do not warrant that the description is accurate. Where we become aware of any misdescription, we reserve the right to correct any error or omission.',
          subElements: [],
        },
        {
          content:
            'Images have been provided for illustrative purposes only and we do not guarantee that any image will reproduce in true colour nor that any given image will reflect or portray the full design or options relating to that product.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Product Descriptions',
      elements: [
        {
          content:
            'We strive to ensure that our products are described as accurately as possible on our website, however we do not warrant that the description is accurate. Where we become aware of any misdescription, we reserve the right to correct any error or omission.',
          subElements: [],
        },
        {
          content:
            'Images have been provided for illustrative purposes only and we do not guarantee that any image will reproduce in true colour nor that any given image will reflect or portray the full design or options relating to that product.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Product Orders',
      elements: [
        {
          content:
            'Our products are for sale to adults over the age of eighteen (18) years. By proceeding to purchase through our website, you acknowledge that you are over 18 years of age.',
          subElements: [],
        },
        {
          content:
            'We endeavour to ensure that our product list is current however we give no undertaking as to the availability of any product advertised on our website.',
          subElements: [],
        },
        {
          content:
            'All prices are in Australian Dollars (AUD) and are exclusive of GST. Our price list can be accessed from our home page and we reserve the right to amend our prices at any time.',
          subElements: [],
        },
        {
          content:
            'Packaging and postage is an additional charge, calculated at time of purchase.',
          subElements: [],
        },
        {
          content:
            'When you order from us, we require you to provide your name, address for delivery, your email address, telephone contact and credit card details. We undertake to take due care with this information; however in providing us with such information you accept that we are not liable for its misuse due to error in transmission or virus or malware.',
          subElements: [],
        },
        {
          content:
            'We undertake to accept or reject your order within 3 days. If we have not responded to you within 3 days, your offer is deemed to be rejected. We are not required to give reasons for rejecting your offer to purchase however the most likely reason for rejecting your offer will be that we do not currently have that product in stock.',
          subElements: [],
        },
        {
          content:
            'Delivery of your ordered product/s will be as set out on our website. Title in the goods passes to you when we have received payment. Our terms of payment are set out on the order page.',
          subElements: [],
        },
        {
          content:
            'All risk of loss or damage to the goods passes to you when we despatch the goods.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Order Cancellation Due To Error',
      elements: [
        {
          content:
            'Where a product has been listed at the incorrect price or with incorrect descriptive information or image due to typographical error or similar oversight, we reserve the right to cancel a transaction. Where your credit card has been charged, we will immediately refund your credit card for the total amount debited.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Product Returns',
      elements: [
        {
          content:
            'We undertake to reimburse you for any product delivered to you that is faulty or is in a damaged condition. If you wish to return a faulty or damaged product, you must notify us through our designated “contact us” webpage where we set out our requirements relating to return of such goods.',
          subElements: [],
        },
        {
          content:
            'If we are unable at the time of return to replace or exchange returned goods, we undertake to reimburse your credit card for the amount initially debited for the purchase including packaging and postage charges.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Site Access',
      elements: [
        {
          content:
            'When you visit our website, we give you a limited licence to access and use our information for personal use.',
          subElements: [],
        },
        {
          content:
            'You are permitted to download a copy of the information on this website to your computer for your personal use only provided that you do not delete or change any copyright symbol, trade mark or other proprietary notice. Your use of our content in any other way infringes our intellectual property rights.',
          subElements: [],
        },
        {
          content:
            'Except as permitted under the Copyright Act 1968 (Cth), you are not permitted to copy, reproduce, republish, distribute or display any of the information on this website without our prior written permission.',
          subElements: [],
        },
        {
          content:
            'The licence to access and use the information on our website does not include the right to use any data mining robots or other extraction tools. The licence also does not permit you to metatag or mirror our website without our prior written permission. We reserve the right to serve you with notice if we become aware of your metatag or mirroring of our website.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Hyperlinks',
      elements: [
        {
          content:
            'This website may from time to time contain hyperlinks to other websites. Such links are provided for convenience only and we take no responsibility for the content and maintenance of or privacy compliance by any linked website. Any hyperlink on our website to another website does not imply our endorsement, support, or sponsorship of the operator of that website nor of the information and/or products which they provide.',
          subElements: [],
        },
        {
          content:
            'Linking our website is not permitted. We reserve the right to serve you with notice if we become aware of such linking.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Intellectual Property Rights',
      elements: [
        {
          content:
            'The copyright to all content on this website including applets, graphics, images, layouts and text belongs to us or we have a licence to use those materials.',
          subElements: [],
        },
        {
          content:
            'All trade marks, brands and logos generally identified either with the symbols TM or ® which are used on this website are either owned by us or we have a licence to use them. Your access to our website does not license you to use those marks in any commercial way without our prior written permission.',
          subElements: [],
        },
        {
          content:
            'Any comment, feedback, idea or suggestion (called “Comments”) which you provide to us through this website becomes our property. If in future we use your Comments in promoting our website or in any other way, we will not be liable for any similarities which may appear from such use. Furthermore, you agree that we are entitled to use your Comments for any commercial or non-commercial purpose without compensation to you or to any other person who has transmitted your Comments.',
          subElements: [],
        },
        {
          content:
            'If you provide us with Comments, you acknowledge that you are responsible for the content of such material including its legality, originality and copyright.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Disclaimers',
      elements: [
        {
          content:
            'Whilst we have taken all due care in providing the information on our website, we do not provide any warranty either express or implied including without limitation warranties of title or implied warranties of merchantability or fitness for a particular purpose.',
          subElements: [],
        },
        {
          content:
            'To the extent permitted by law, any condition or warranty which would otherwise be implied into these terms and conditions is excluded.',
          subElements: [],
        },
        {
          content:
            'We also take all due care in ensuring that our website is free of any virus, worm, Trojan horse and/or malware, however we are not responsible for any damage to your computer system which arises in connection with your use of our website or any linked website.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Statutory Guarantees and Warranties to Consumers',
      elements: [
        {
          content:
            'Schedule 2 of the Competition and Consumer Act 2010 (“C&amp;C Act”) defines a consumer. Under the C&amp;C Act we are a supplier of either goods or services or both to you, and as a consumer the C&amp;C Act gives you statutory guarantees. Attached to the Standard Terms and Conditions are:-',
          subElements: [
            'Schedule 2 of the C&amp;C Act; and',
            'those statutory guarantees, all of which are given by us to you if you are a consumer.',
          ],
        },
        {
          content:
            'If you are a consumer within the meaning of Schedule 2 of the C&amp;C Act of our goods or services then we give you a warranty that at the time of supply of those goods or services to you, if they are defective then:-',
          subElements: [
            'We will repair or replace the goods or any part of them that is defective; or',
            'Provide again or rectify any services or part of them that are defective; or',
            'Wholly or partly recompense you if they are defective.',
          ],
        },
        {
          content:
            'As a consumer under the C&amp;C Act you may be entitled to receive from us notices under Schedule 2 section 103 of the C&amp;C Act. In that regard:-',
          subElements: [
            'If you are a consumer within the meaning of Schedule 2 of the C&amp;C Act and the goods or services we are providing relate to the repair of consumer goods then we will give you any notice which we are obliged to give you under Schedule 2 section 103 of the C&amp;C Act.',
            'If we are a repairer of goods capable of retaining user-generated data then we hereby give you notice that the repair of those goods may result in the loss of the data.',
            'If we are a repairer and our practice is to supply refurbished goods as an alternative to repairing your defective goods or to use refurbished parts in the repair, then we give you notice that the goods presented by you to us for repair may be replaced by refurbished goods of the same type rather than being repaired. We also give you notice that we may use in the repair of your goods, refurbished parts.',
          ],
        },
      ],
    },
    {
      title: 'Limitation of Liability',
      elements: [
        {
          content:
            'If you are not a consumer within the meaning of Schedule 2 of the C&amp;C Act then this clause applies to you. If you are a consumer within the meaning of the C&amp;C Act then this clause has no effect whatsoever to in any way limit our liability or your rights. If you are not a consumer:-',
          subElements: [
            'To the full extent permitted by law, our liability for breach of an implied warranty or condition is limited to the supply of the services again or payment of the costs of having those services supplied again.',
            'We accept no liability for any loss whatsoever including consequential loss suffered by you arising from services we have supplied.',
            'We do not accept liability for anything contained in the post of a user or in any form of communication which originates with a user and not with Us.',
            'We do not participate in any way in the transactions between our users.',
          ],
        },
      ],
    },
    {
      title: 'Indemnity',
      elements: [
        {
          content:
            'By accessing our website, you agree to indemnify and hold us harmless from all claims, actions, damages, costs and expenses including legal fees arising from or in connection with your use of our website.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Force Majeure',
      elements: [
        {
          content:
            'If a Force Majeure event causing delay continues for more than 30, we may terminate this Agreement by giving at least 7 Notice to you. “Force Majeure” means any act, circumstance or omission over which we could not reasonably have exercised control.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Jurisdiction',
      elements: [
        {
          content:
            'These terms and conditions are to be governed by and construed in accordance with the laws of NSW and any claim made by either party against the other which in any way arises out of these terms and conditions will be heard in NSW and you agree to submit to the jurisdiction of those Courts.',
          subElements: [],
        },
        {
          content:
            'If any provision in these terms and conditions is invalid under any law the provision will be limited, narrowed, construed or altered as necessary to render it valid but only to the extent necessary to achieve such validity. If necessary the invalid provision will be deleted from these terms and conditions and the remaining provisions will remain in full force and effect.',
          subElements: [],
        },
      ],
    },
    {
      title: 'Privacy',
      elements: [
        {
          content:
            'We undertake to take all due care with any information which you may provide to us when accessing our website. However we do not warrant and cannot ensure the security of any information which you may provide to us. Information you transmit to us is entirely at your own risk although we undertake to take reasonable steps to preserve such information in a secure manner.',
          subElements: [],
        },
        {
          content:
            'Our compliance with privacy legislation is set out in our separate Privacy Policy which may be accessed from our home page.',
          subElements: [],
        },
      ],
    },
  ],
};
