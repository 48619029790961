import React from 'react';
import { ModalConsumer } from './ModalContext';
import LoginModal from '../components/modules/LoginModal';

// eslint-disable-next-line react/display-name
export default () => (
  <ModalConsumer>
    {props => (
      <LoginModal
        open={props.open}
        onClose={() => {
          props.setModal('CLOSE');
          props.onClose();
        }}
      />
    )}
  </ModalConsumer>
);
