import React, { FC } from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { useStyles } from './TextArea.styles';
import { TextAreaProps } from './TextArea.props';
import Typo from '../Typo';

const TextAreaView: FC<TextAreaProps> = (props: TextAreaProps) => {
  const { value, placeholder, onChange, rows, rowsMax, style } = props;
  const classes = useStyles(props);
  return (
    <Grid className={classes.container} container style={style}>
      {props.label ? (
        <Grid item xs={12} style={{ width: '100%', marginBottom: '1em' }}>
          <Typo variant="body1">{props.label}</Typo>
        </Grid>
      ) : null}
      <Grid item xs={12} style={{ width: '100%', display: 'flex' }}>
        <TextareaAutosize
          rows={rows || 12}
          rowsMax={rowsMax || rows || 12}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={classes.textArea}
          style={{ paddingRight: '5em' }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(TextAreaView);
