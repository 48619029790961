import React, { FC } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './Tile.styles';
import { TileProps } from './Tile.props';

const TileView: FC<TileProps> = ({
  text,
  iconUrl,
  backgroundColor,
  iconAlignedLeft,
  isGhostTile,
  isActive,
  onClick,
  style,
}: TileProps) => {
  const classes = useStyles({
    iconUrl,
    backgroundColor,
    iconAlignedLeft,
    isGhostTile,
    isActive,
  });
  return (
    <Button style={style || {}} onClick={onClick} className={classes.button}>
      <Typography className={classes.typography}>{text}</Typography>
    </Button>
  );
};

export default React.memo(TileView);
