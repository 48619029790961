import React, { FC } from 'react';
import { Avatar } from '@material-ui/core';
// @ts-ignore
import UserCircle from 'assets/svgs/user-circle.svg';
import { useStyles } from './Avatar.styles';
import { AvatarProps } from './Avatar.props';

const AvatarView: FC<AvatarProps> = props => {
  const classes = useStyles(props);

  return (
    <Avatar
      alt="Avatar image"
      src={props.src || UserCircle}
      className={classes.avatar}
    />
  );
};

export default React.memo(AvatarView);
