import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { LockIcon, EmailIcon } from 'assets/svgs';
import { useStyles } from './LoginForm.styles';
import { LoginFormProps } from './LoginForm.props';
import {
  GoogleLogin,
  FacebookLogin,
  Typo,
  Button,
  InputField,
} from '../../primitives';
import { LoginModalForms } from '../LoginModal/LoginModal.const';

const LoginFormView: FC<LoginFormProps> = (props: LoginFormProps) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const onSignUpClick = props.openModal
    ? props.openModal(LoginModalForms.SIGNUP)
    : () => {};
  const onResetPasswordClick = props.openModal
    ? props.openModal(LoginModalForms.RESET)
    : () => {};
  const onCloseMenu = props.openModal
    ? props.openModal(LoginModalForms.CLOSE)
    : () => {};
  const onLoginCallback = (res: {
    status: 'success' | 'error';
    message: string;
  }) => {
    if (res.status === 'success') {
      onCloseMenu();
      setError('');
    } else {
      setError(res.message);
    }
  };

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Login Form
      </Typo>
      <Box className={classes.subHeading}>
        <Typo variant="body2" color="lightGrey">
          Enter your details to get started.
        </Typo>
        {Boolean(error) && (
          <Typo variant="body1" color="red">
            {error}
          </Typo>
        )}
      </Box>
      <GoogleLogin onClickCallback={onLoginCallback} />
      <FacebookLogin onClickCallback={onLoginCallback} />
      <Typo variant="body2" className={classes.or}>
        or
      </Typo>
      <form
        onSubmit={event => {
          event.preventDefault();
          props.onLogin();
        }}
      >
        <InputField
          icon={EmailIcon}
          id="email"
          placeholder="Email Address"
          className={classes.field}
          value={props.email}
          onChange={props.onEmailChange}
        />
        <InputField
          icon={LockIcon}
          id="password"
          type="password"
          placeholder="Password"
          className={classes.field}
          value={props.password}
          onChange={props.onPasswordChange}
        />
        {props.error ? (
          <Typo variant="body1" color="red" className={classes.errorMessage}>
            {props.error}
          </Typo>
        ) : null}
        <Button
          text="Log in"
          className={classes.button}
          // onClick={props.onLogin}
          type="submit"
        />
      </form>
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Forgot password?{' '}
          <Typo
            variant="body1"
            component="a"
            color="blue"
            onClick={onResetPasswordClick}
            className={classes.link}
          >
            Reset here
          </Typo>
        </Typo>
        <Typo variant="body1" color="lightGrey">
          Don&lsquo;t have an account?{' '}
          <Typo
            variant="body1"
            component="a"
            color="blue"
            onClick={onSignUpClick}
            className={classes.link}
          >
            Sign up
          </Typo>
        </Typo>
      </Box>
    </>
  );
};

export default React.memo(LoginFormView);
