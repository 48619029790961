import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { Profile } from 'types/Profile';
import { ProfileState } from 'types/store/ProfileState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE: ProfileState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<ProfileState, any>(
  {
    ...createAsyncReducers<Profile>('USER/GET_PROFILE'),
    'USER/UPDATE_PROFILE_FULFILLED': (state, action) => action.payload,
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
