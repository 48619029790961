// @ts-ignore
export { ReactComponent as CloseIcon } from './close-icon.svg';
/*
// @ts-ignore
export { ReactComponent as Logo } from './logo.svg';
*/
// @ts-ignore
export { ReactComponent as CameraIcon } from './camera-icon.svg';
// @ts-ignore
export { ReactComponent as GoogleIcon } from './google-icon.svg';
// @ts-ignore
export { ReactComponent as FacebookIcon } from './facebook-icon.svg';
// @ts-ignore
export { ReactComponent as EmailIcon } from './email-icon.svg';
// @ts-ignore
export { ReactComponent as LockIcon } from './lock-icon.svg';
// @ts-ignore
export { ReactComponent as UserIcon } from './user-icon.svg';
// @ts-ignore
export { ReactComponent as WorkIcon } from './work-icon.svg';
// @ts-ignore
export { ReactComponent as EducationIcon } from './education-icon.svg';
// @ts-ignore
export { ReactComponent as FunctionsIcon } from './functions-icon.svg';
// @ts-ignore
export { ReactComponent as OutdoorsIcon } from './outdoors-icon.svg';
// @ts-ignore
export { ReactComponent as SportsIcon } from './sports-icon.svg';
// @ts-ignore
export { ReactComponent as AirplacerIcon } from './airplacer-icon.svg';
// @ts-ignore
export { ReactComponent as PlaceIcon } from './place-icon.svg';
// @ts-ignore
export { ReactComponent as HeartIcon } from './heart-icon.svg';
// @ts-ignore
export { ReactComponent as FilterIconActive } from './filter-icon-active.svg';
// @ts-ignore
export { ReactComponent as ShowMap } from './show-map.svg';
// @ts-ignore
export { ReactComponent as HideMap } from './hide-map.svg';
// @ts-ignore
export { ReactComponent as PinRed } from './pin-red.svg';
// @ts-ignore
export { ReactComponent as PinBlue } from './pin-blue.svg';
// @ts-ignore
export { ReactComponent as PinYellow } from './pin-yellow.svg';
// @ts-ignore
export { ReactComponent as PinRedSelected } from './pin-red-selected.svg';
// @ts-ignore
export { ReactComponent as PinYellowSelected } from './pin-yellow-selected.svg';
// @ts-ignore
export { ReactComponent as PinBlueSelected } from './pin-blue-selected.svg';
// @ts-ignore
export { ReactComponent as ChevDown } from './chev-down.svg';
// @ts-ignore
export { ReactComponent as UserCircle } from './user-circle.svg';
// @ts-ignore
export { ReactComponent as SendIcon } from './send-icon.svg';
// @ts-ignore
export { ReactComponent as PendingIcon } from './pending-icon.svg';
// @ts-ignore
export { ReactComponent as DeclinedIcon } from './declined-icon.svg';
// @ts-ignore
export { ReactComponent as ApprovedIcon } from './approved-icon.svg';
// @ts-ignore
export { ReactComponent as BackButtonIcon } from './back-button.svg';
