import { makeCreateActions, presetActions } from 'utils/Redux';
import { createAction } from 'redux-actions';

const ns = 'CONTACT';
const createContactActions = makeCreateActions(ns);

const contactActions = {
  ...createContactActions({
    submit: presetActions.makeAsyncAction(`${ns}/SUBMIT`),
  }),
  clear: createAction(`${ns}/CLEAR`),
};

export default contactActions;
