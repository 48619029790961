import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: 'transparent',
      border: '1px solid rgb(5, 195, 217)',
      borderRadius: '5px',
      width: '100%',
      height: '50px',
      color: 'rgb(5, 195, 217)',
      '&:hover': {
        backgroundColor: 'rgb(5, 195, 217)',
        color: 'white',
      },
    },
    selectedButton: {
      backgroundColor: 'rgb(5, 195, 217)',
      border: '1px solid rgb(5, 195, 217)',
      borderRadius: '5px',
      width: '100%',
      height: '50px',
      color: 'white',
    },
    typography: {
      color: 'inherit',
    },
  }),
);
