import { createMuiTheme, Theme } from '@material-ui/core/styles';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import props from './props';

const theme: Theme = createMuiTheme({
  overrides,
  palette,
  typography,
  props,
});

export default theme;
