import { handleActions } from 'redux-actions';
import { presetReducers, asyncData, createAsyncReducers } from 'utils/Redux';
import { ForgotPassword } from 'types/ForgotPassword';
import { ForgotPasswordState } from 'types/store/ForgotPasswordState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';

const DEFAULT_STATE: ForgotPasswordState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<ForgotPasswordState, any>(
  {
    ...createAsyncReducers<ForgotPassword>('AUTH/FORGOT_PASSWORD'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
