import React from 'react';
import { Grid, TextareaAutosize } from '@material-ui/core';
import { useStyles } from './TextBlock.styles';
import Typo from '../Typo';

const TextBlockView = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} container>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Typo variant="body1" color="darkGrey">
          {question}
        </Typo>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ width: '100%', display: 'flex', paddingTop: '1em' }}
      >
        <Typo variant="body1" color="lightGrey">
          {answer}
        </Typo>
      </Grid>
    </Grid>
  );
};

export default React.memo(TextBlockView);
