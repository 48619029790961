import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'USER';
const createUserActions = makeCreateActions(ns);

const userActions = createUserActions({
  getProfile: presetActions.makeAsyncAction(`${ns}/GET_PROFILE`),
  updateProfile: presetActions.makeAsyncAction(`${ns}/UPDATE_PROFILE`),
  addFavourite: presetActions.makeAsyncAction(`${ns}/ADD_FAVOURITE`),
  removeFavourite: presetActions.makeAsyncAction(`${ns}/REMOVE_FAVOURITE`),
  getFavourites: presetActions.makeAsyncAction(`${ns}/GET_FAVOURITES`),
});

export default userActions;
