import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from 'react-google-login';
import authThunks from 'thunks/auth';
import socialsData from 'consts/socials';
import { GoogleIcon } from 'assets/svgs';
import { useStyles } from './GoogleLogin.styles';
import { GoogleLoginProps } from './GoogleLogin.props';
import Button from '../Button';

const GoogleLoginView: FC<GoogleLoginProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // @ts-ignore
  const responseGoogle = response => {
    if (!response.error) {
      dispatch(
        authThunks.googleAccess(
          {
            type: 'google',
            // eslint-disable-next-line @typescript-eslint/camelcase
            access_token: response.accessToken,
            email: response.email,
            name: response.name,
            picture: response.picture,
            userID: response.userID,
          },
          {
            finishUpdating: () => {
              if (props.onClickCallback) {
                props.onClickCallback({
                  status: 'success',
                  message: 'Success',
                });
              }
            },
            updateErrorMessage: () => {
              if (props.onClickCallback) {
                props.onClickCallback({
                  status: 'error',
                  message: `Something went wrong, try again later.`,
                });
              }
            },
          },
        ),
      );
    } else {
      // @TODO something wen't wrong error
    }
  };

  return (
    <GoogleLogin
      clientId={socialsData.google.appId}
      buttonText="Login"
      autoLoad={false}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      render={renderProps => (
        <Button
          icon={GoogleIcon}
          text="Continue with Google"
          color="darkGrey"
          backgroundColor="white"
          className={classes.button}
          onClick={renderProps.onClick}
        />
      )}
    />
  );
};

export default React.memo(GoogleLoginView);
