import React, { FC, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { CreateAccount } from 'types/CreateAccount';
import { routerActions } from 'store/actions';
import ROUTES from 'routes';
import useForm from 'utils/Hooks/useForm';
import { pluck, filter } from 'ramda';
import View from './CreateAccountForm.view';
import { CreateAccountFormPublicProps } from './CreateAccountForm.props';
import authThunks from '../../../thunks/auth';

const CreateAccountForm: FC<CreateAccountFormPublicProps> = (
  props: CreateAccountFormPublicProps,
) => {
  const dispatch = useDispatch();
  const [responseError, setResponseError] = useState('');
  const createAccount = useSelectorSafe<CreateAccount>(
    // @ts-ignore
    store => store.createAccount,
    asyncData(ASYNC_STATUS.INITIAL),
  );

  const serverErrors = useMemo(
    () => ({
      emailAddress: { error: (createAccount.errors.errors || {}).email },
    }),
    [createAccount.errors],
  );

  const {
    state,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
    disable,
  } = useForm(
    {
      fullName: { value: '', error: '' },
      emailAddress: { value: '', error: '' },
      password: { value: '', error: '' },
      confirmPassword: { value: '', error: '' },
    },
    {
      fullName: (value?: string) => (!value ? 'Name is required.' : null),
      emailAddress: (value?: string) => {
        if (!value) {
          return 'Email Address is required.';
        }
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          return 'You must enter a valid email address.';
        }
        return null;
      },
      password: (value?: string) => (!value ? 'Password is required.' : null),
      // @ts-ignore
      confirmPassword: (value?: string, s) => {
        if (!value) {
          return 'Confirm Password is requried.';
        }
        if (s.password.value !== value) {
          return 'Confirm Password and Password must match.';
        }

        return null;
      },
    },
    // @ts-ignore
    s => {
      dispatch(
        authThunks.createAccount(
          {
            fullName: s.fullName.value,
            email: s.emailAddress.value,
            password: s.password.value,
          },
          {
            finishUpdating: () => {
              setResponseError('');
              if (props.openModal) {
                props.openModal('CHECK_EMAIL', {
                  email: s.emailAddress.value,
                  action: 'verify-email',
                })();
              }
            },
            updateErrorMessage: error => {
              if (error && error.message) {
                setResponseError(error.message);
              } else {
                setResponseError('');
              }
            },
          },
        ),
      );
    },
    serverErrors,
  );

  // @ts-ignore
  const errors: string[] = Object.values(
    pluck<'error', { error?: string; touched?: boolean }>(
      'error',
      filter(s => Boolean(s.touched), state),
    ),
  ).filter(v => Boolean(v));

  if (responseError) {
    errors.push(responseError);
  }
  return (
    <View
      {...props}
      toTerms={() => dispatch(routerActions.link(ROUTES.TERMS))}
      toPrivacy={() => dispatch(routerActions.link(ROUTES.PRIVACY_POLICY))}
      onCreateAccount={handleOnSubmit}
      errors={errors}
      fullName={state.fullName}
      emailAddress={state.emailAddress}
      password={state.password}
      confirmPassword={state.confirmPassword}
      setPassword={handleOnChange('password')}
      setFullName={handleOnChange('fullName')}
      setEmailAddress={handleOnChange('emailAddress')}
      setConfirmPassword={handleOnChange('confirmPassword')}
      setTouchedPassword={handleOnBlur('password')}
      setTouchedFullName={handleOnBlur('fullName')}
      setTouchedEmailAddress={handleOnBlur('emailAddress')}
      setTouchedConfirmPassword={handleOnBlur('confirmPassword')}
      disabled={disable}
    />
  );
};

export default React.memo(CreateAccountForm);
