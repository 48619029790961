import { makeCreateActions, presetActions } from 'utils/Redux';

const ns = 'ENTITY';
const createContactActions = makeCreateActions(ns);

const entityActions = createContactActions({
  get: presetActions.makeAsyncAction(`${ns}/ENTITY`),
  getById: presetActions.makeAsyncAction(`${ns}/GET_ENTITY_BY_ID`),
  getFilters: presetActions.makeAsyncAction(`${ns}/GET_ENTITY_FILTERS`),
});

export default entityActions;
