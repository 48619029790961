import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import socialsData from 'consts/socials';
import authThunks from 'thunks/auth';
import { useStyles } from './FacebookLogin.styles';
import { FacebookLoginProps } from './FacebookLogin.props';
import { FacebookIcon } from '../../../assets/svgs';
import Button from '../Button';

const FacebookLoginView: FC<FacebookLoginProps> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // @ts-ignore
  const responseFacebook = response => {
    if (response.status !== 'unknown') {
      dispatch(
        authThunks.facebookAccess(
          {
            type: 'facebook',
            // eslint-disable-next-line @typescript-eslint/camelcase
            access_token: response.accessToken,
            email: response.email,
            name: response.name,
            picture: response.picture,
            userID: response.userID,
          },
          {
            finishUpdating: () => {
              if (props.onClickCallback) {
                props.onClickCallback({
                  status: 'success',
                  message: 'Success',
                });
              }
            },
            updateErrorMessage: () => {
              if (props.onClickCallback) {
                props.onClickCallback({
                  status: 'error',
                  message: `Something went wrong, try again later.`,
                });
              }
            },
          },
        ),
      );
    } else if (response.status === 'unknown') {
      // do nothing
    } else if (props.onClickCallback) {
      props.onClickCallback({
        status: 'error',
        message: `Something went wrong, try again later.`,
      });
    }
  };
  return (
    <FacebookLogin
      appId={socialsData.facebook.appId}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      // @ts-ignore
      render={renderProps => (
        <Button
          icon={FacebookIcon}
          text="Continue with Facebook"
          color="white"
          backgroundColor="fbBlue"
          className={classes.button}
          onClick={renderProps.onClick}
        />
      )}
    />
  );
};

export default React.memo(FacebookLoginView);
