import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import authThunks from 'thunks/auth';
import { UserState } from 'types/store/UserState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { asyncData } from 'utils/Redux';
import { routerActions } from 'store/actions';
import ROUTES from 'routes';
import { LoginFormPublicProps } from './LoginForm.props';
import View from './LoginForm.view';

const userFallback: UserState = asyncData(ASYNC_STATUS.ERROR, [
  { message: 'Could not load user' },
]);

const FieldContainer: FC<LoginFormPublicProps> = (
  props: LoginFormPublicProps,
) => {
  const { status } = useSelectorSafe<UserState>(
    state => state.user,
    userFallback,
  );

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (status === 'COMPLETE' && props.onClose) {
      props.onClose();
    }
  }, [status]);

  return (
    <View
      {...props}
      email={email}
      password={password}
      onEmailChange={event => setEmail(event.target.value)}
      onPasswordChange={event => setPassword(event.target.value)}
      error={
        status === 'ERROR' && isDirty
          ? 'Invalid credentials, please try again.'
          : undefined
      }
      onLogin={() => {
        setIsDirty(true);
        dispatch(
          authThunks.login({
            email,
            password,
          }),
        );
      }}
    />
  );
};

export default React.memo(FieldContainer);
