import user from './user';
import token from './token';
import exampleListings from './exampleListings';
import profile from './profile';
import createAccount from './createAccount';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import contact from './contact';
import filter from './filter';
import entity from './entity';
import updatePassword from './updatePassword';
import booking from './booking';
import filterOptions from './filterOptions';
import entityDetails from './entityDetails';
import bookingDetails from './bookingDetails';
import favourites from './favourites';

export default {
  user,
  token,
  exampleListings,
  profile,
  createAccount,
  forgotPassword,
  resetPassword,
  contact,
  filter,
  entity,
  updatePassword,
  booking,
  filterOptions,
  entityDetails,
  bookingDetails,
  favourites,
};
