import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { FieldProps } from './Field.props';
import Typo from '../Typo';

const FieldView: FC<FieldProps> = ({ label, value, className }: FieldProps) => {
  return (
    <Box
      display="flex"
      alignItems="space-between"
      justifyContent="space-between"
      className={className}
    >
      <Typo variant="body1" color="lightGrey">
        {label}
      </Typo>
      <Typo variant="body1" style={{ fontWeight: 'bold' }}>
        {value}
      </Typo>
    </Box>
  );
};

export default React.memo(FieldView);
