import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ForgotPasswordState } from 'types/store/ForgotPasswordState';
import View from './CheckYourEmailDialog.view';
import authThunks from '../../../thunks/auth';
import { CheckYourEmailDialogPublicProps } from './CheckYourEmailDialog.props';

const FieldContainer: FC<CheckYourEmailDialogPublicProps> = (
  props: CheckYourEmailDialogPublicProps,
) => {
  const dispatch = useDispatch();
  const forgotPassword = useSelectorSafe<ForgotPasswordState>(
    // @ts-ignore
    store => store.forgotPassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );
  const [message, setMessage] = useState({});
  const [clicked, setClicked] = useState(false);
  const resendForgotPasswordEmail = () => {
    setClicked(true);
    dispatch(
      authThunks.forgotPassword({
        email: props.params.email,
      }),
    );
  };
  const resendVerifyEmail = () => {
    setClicked(true);
    dispatch(
      authThunks.sendVerificationEmail(
        {
          email: props.params.email,
        },
        {
          finishUpdating: () => {
            setMessage({
              type: 'success',
              message: `Another email has been sent.`,
            });
          },
          updateErrorMessage: (err: any) => {
            setMessage({
              type: 'error',
              message: `Something went wrong try again later.`,
            });
          },
        },
      ),
    );
  };
  useEffect(() => {
    if (forgotPassword.status === 'COMPLETE' && clicked) {
      setMessage({ type: 'success', message: 'Another email has been sent.' });
    }
    if (forgotPassword.status === 'ERROR' && clicked) {
      setMessage({
        type: 'error',
        message: 'Something went wrong try again later.',
      });
    }
  }, [forgotPassword.status]);

  return (
    <View
      {...props}
      message={message}
      resendForgotPasswordEmail={resendForgotPasswordEmail}
      resendVerifyEmail={resendVerifyEmail}
    />
  );
};

export default React.memo(FieldContainer);
