import React from 'react';
import Avatar from '../../primitives/Avatar';
import Typo from '../../primitives/Typo';
import { useStyles } from './HostedBy.styles';

// @ts-ignore
const HostedByView = ({ host, onClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.hostedByContainer} onClick={onClick}>
      <Avatar square={true} size={30} />
      <Typo variant="body1" color="lightGrey">
        Hosted By{' '}
        <Typo variant="h5" color="darkGrey" component="span">
          {' '}
          {host ? host.name : ''}
        </Typo>
      </Typo>
    </button>
  );
};

export default React.memo(HostedByView);
