import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';
import { fallback } from 'utils/Data';
import { LocationSearchProps } from './LocationSearch.props';

export const useStyles = makeStyles(() =>
  createStyles({
    inputField: {
      borderColor: colors.blue,
    },
    container: {
      position: 'relative',
      width: '100%',
      maxWidth: (props: LocationSearchProps) =>
        fallback(
          // @ts-ignore
          t => t.width,
          '100%',
          props,
        ),
    },
    suggestionsContainer: {
      position: 'absolute',
      width: '100%',
      background: colors.white,
      zIndex: 20,
      borderRadius: '5px',
      top: '55px',
    },
    active: {
      backgroundColor: colors.blue,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
    },
    inactive: {
      backgroundColor: colors.white,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
    },
  }),
);
