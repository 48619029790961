import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { AppBarItemProps } from './AppBarItem.props';

const AppBarItemView: FC<AppBarItemProps> = ({
  text,
  onClick,
  children,
}: AppBarItemProps) => {
  return (
    <Button color="inherit" onClick={onClick}>
      {text}
      {children}
    </Button>
  );
};

export default React.memo(AppBarItemView);
