import React from 'react';
import View from './Breadcrumbs.view';

const data = {
  elements: [
    {
      title: 'My bookings',
      onClick: () => {},
    },
    {
      title: 'My bookings History',
      onClick: () => {},
    },
  ],
};

const BreadcrumbsContainer = ({
  elements,
  hideLast,
}: {
  elements?: { title: string; onClick: () => void }[];
  hideLast?: boolean;
}) => {
  const filteredElements =
    elements && hideLast ? elements.splice(0, elements.length - 1) : elements;
  return (
    <View
      elements={filteredElements || data.elements}
      hideLast={hideLast || false}
    />
  );
};

export default BreadcrumbsContainer;
