import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './PasswordChangedDialog.styles';
import { PasswordChangedDialogProps } from './PasswordChangedDialog.props';
import { Typo } from '../../primitives';
import { LoginModalForms } from '../LoginModal/LoginModal.const';

const ResetPasswordFormView: FC<PasswordChangedDialogProps> = props => {
  const classes = useStyles();
  const onClickLogin = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Password Changed
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        All done! it may take a couple of minutes for the change to register.
      </Typo>
      <Box className={classes.footer}>
        <Typo variant="body1" color="blue" component="a" onClick={onClickLogin}>
          Log in
        </Typo>
      </Box>
    </>
  );
};

export default React.memo(ResetPasswordFormView);
