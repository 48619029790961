import { authActions, userActions } from 'store/actions';
import { Dispatch } from 'redux';
import {
  facebookAccessEndpoint,
  getProfileEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';

interface FacebookCredentials {
  type: string;
  // eslint-disable-next-line camelcase
  access_token: string;
  email: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  picture: any;
  userID: string;
}

interface Data {}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const facebookAccess = (
  credentials: FacebookCredentials,
  signalling: Signalling,
) => (dispatch: Dispatch, getState: () => Store) => {
  dispatch(
    authActions.login(
      apiFetch<FacebookCredentials, User>(facebookAccessEndpoint, credentials)(
        dispatch,
        getState,
      )
        .then(user => {
          dispatch(
            userActions.getProfile(
              apiFetch<Data, any>(getProfileEndpoint, {})(
                dispatch,
                getState,
              ).then(p => p.data),
            ),
          );
          if (signalling.finishUpdating) {
            signalling.finishUpdating(user);
          }
          return user;
        })
        .catch(err => {
          if (signalling.updateErrorMessage) {
            signalling.updateErrorMessage(err);
          }

          throw err;
        }),
    ),
  );
};

export default facebookAccess;
