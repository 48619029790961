export const fontFamilies = {
  montserrat: 'Montserrat, sans-serif',
  gilroy: 'Gilroy, Montserrat, sans-serif',
};

export const rootFontSizePx = 16;

export const colors = {
  primaryBlue: '#29CBCB',
  primaryWhite: '#FEFEFE',
  darkTeal: '#2A3232',
  darkBlack: '#212424',
  lightBlue: '#FFFFFF',
  lightCyan: '#B8DDDD',
  lightDark: '#5C5E5E',
  darkGreen: '#156363',

  // App colors
  darkGrey: '#404040',
  lightGrey: '#A0AAAD',
  white: '#FFFFFF',
  blue: '#05C3DE',
  red: '#E40046',
  green: '#00B2A9',
  yellow: '#FFB81C',
  orange: '#FF9887',
  fbBlue: '#3B5998',
};
