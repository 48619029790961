import React, { FC } from 'react';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import View from './LocationCard.view';
import { LocationCardPublicProps } from './LocationCard.props';

const SelectContainer: FC<LocationCardPublicProps> = props => {
  const isLoggedIn = Boolean(useSelectorSafe(state => state.token) || false);
  return <View {...props} isLoggedIn={isLoggedIn} />;
};

export default React.memo(SelectContainer);
