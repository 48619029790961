import { useEffect, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import isBefore from 'date-fns/isBefore';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';

export default (
  onChange: (v: { to?: Date; from?: Date }) => void = () => {},
  currentDateAvailable: (date: any) => boolean,
  // { reset = false },
) => {
  // @ts-ignore
  const [{ from, to }, setDate] = useState({});
  const modifiers = { start: from, end: to };
  const [locked, setLocked] = useState(false);

  const handleDayClick = (
    day: Date,
    disablePast: boolean,
    { disabled }: { disabled: boolean } = { disabled: false },
  ) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Prevent previous date or disabled date
    if ((disablePast && day < today) || disabled) {
      return;
    }
    if (locked) {
      setDate({ from: day, to: undefined });
      setLocked(false);
      return;
    }

    // reset range selection on third click
    if (from && to && day) {
      setDate({ from: day, to: undefined });
      return;
    }

    if (from && day) {
      let startSelection;
      let endSelection;
      if (isBefore(from, day)) {
        startSelection = from;
        endSelection = day;
      } else {
        startSelection = day;
        endSelection = from;
      }
      const selectedDates = eachDayOfInterval({
        start: startSelection,
        end: endSelection,
      });
      if (currentDateAvailable) {
        const isInvalid = selectedDates.some(d => !currentDateAvailable(d));
        if (isInvalid) {
          return;
        }
        setDate({ from: startSelection, to: endSelection });
        return;
      }
    }
    setDate({ from: day, to: undefined });
  };

  useEffect(() => {
    onChange({ from, to });
  }, [from, to]);

  const handleReset = () => {
    setDate({});
  };
  const onClose = () => {
    if (to !== (to || from) || !locked) {
      setDate({ from, to: to || from });
      setLocked(true);
    }
  };

  return { handleReset, handleDayClick, modifiers, from, to, onClose };
};
