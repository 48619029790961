import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      pointerEvents: 'auto',
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      borderRadius: '10px',
      marginBottom: '10px',
    },
    image: ({ image }: { image: string }) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${image}')`,
      backgroundSize: 'cover',
    }),
    title: {
      marginBottom: '10px',
      pointerEvents: 'auto',
    },
    address: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    bottomContainer: {
      display: 'flex',
    },
  }),
);
