import { authActions } from 'store/actions';
import { Dispatch } from 'redux';
import { updatePasswordEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { Store } from '../../types/store/Store';

interface AccountCredentials {
  currentPassword: string;
  newPassword: string;
}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const updatePassword = (
  credentials: AccountCredentials,
  signalling?: Signalling,
) => (dispatch: Dispatch, getState: () => Store) => {
  const updatePasswordResponse = apiFetch<AccountCredentials, any>(
    updatePasswordEndpoint,
    credentials,
  )(dispatch, getState)
    .then(response => {
      if (signalling && signalling.finishUpdating) {
        signalling.finishUpdating(response.data);
      }
      return response.data;
    })
    .catch(err => {
      if (signalling && signalling.updateErrorMessage) {
        signalling.updateErrorMessage(err);
      }
    });
  dispatch(authActions.updatePassword(updatePasswordResponse));
  return updatePasswordResponse;
};

export default updatePassword;
