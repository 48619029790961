export default {
  facebook: {
    appId: process.env.REACT_APP_FACEBOOK_APP_ID || '526967474703677',
    appSecret:
      process.env.REACT_APP_FACEBOOK_APP_SECRET ||
      '154e4a623da694a1fa8c49a03ff9f3ad',
    callback: `${process.env.REACT_APP_FRONT_END_URL}/profile`,
  },
  google: {
    appId:
      process.env.REACT_APP_GOOGLE_APP_ID ||
      '549936180657-8hmapkhmr9q7chop0uuq264q7ekkk9cp.apps.googleusercontent.com',
    appSecret:
      process.env.REACT_APP_GOOGLE_APP_SECRET || '_sNSq4ezIYVuXYfH1PZaqesJ',
    callback: `${process.env.FRONTEND_URL}/profile`,
  },
};
