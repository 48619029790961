import React, { FC, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'utils/Hooks/useForm';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import { asyncData } from 'utils/Redux';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { ForgotPasswordState } from 'types/store/ForgotPasswordState';
import authThunks from '../../../thunks/auth';
import { LoginModalForms } from '../LoginModal/LoginModal.const';
import { ResetPasswordFormPublicProps } from './ResetPasswordForm.props';
import View from './ResetPasswordForm.view';

const errMsg = `There is currently no account associated with the provided email address.`;

const FieldContainer: FC<ResetPasswordFormPublicProps> = (
  props: ResetPasswordFormPublicProps,
) => {
  const dispatch = useDispatch();
  const forgotPassword = useSelectorSafe<ForgotPasswordState>(
    // @ts-ignore
    store => store.forgotPassword,
    asyncData(ASYNC_STATUS.INITIAL),
  );

  const serverErrors = useMemo(
    () => ({
      emailAddress: {
        error: forgotPassword.status === 'ERROR' ? errMsg : null,
      },
    }),
    [forgotPassword.status],
  );

  const {
    state,
    disable,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
  } = useForm(
    {
      emailAddress: { value: '', error: '' },
    },
    {
      emailAddress: (email: string) => {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          return 'You must enter a valid email address.';
        }

        return null;
      },
    },
    (s: any) => {
      dispatch(
        authThunks.forgotPassword({
          email: s.emailAddress.value,
        }),
      );
    },
    serverErrors,
  );

  const onLoginClick = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};
  const openCheckEmail = props.openModal
    ? props.openModal(LoginModalForms.CHECK_EMAIL, {
        email: state.emailAddress.value,
        action: 'reset-password',
      })
    : () => {};
  const onSignUpClick = props.openModal
    ? props.openModal(LoginModalForms.SIGNUP)
    : () => {};

  useEffect(() => {
    if (forgotPassword.status === 'COMPLETE' && props.openModal) {
      openCheckEmail();
    }
  }, [forgotPassword.status]);

  return (
    <View
      {...props}
      onLoginClick={onLoginClick}
      onSignUpClick={onSignUpClick}
      onConfirm={handleOnSubmit}
      emailAddress={state.emailAddress}
      disabled={disable}
      setEmailAddress={handleOnChange('emailAddress')}
      setTouchedEmailAddress={handleOnBlur('emailAddress')}
    />
  );
};

export default React.memo(FieldContainer);
