import { Modal } from './LoginModal.props';

export const LoginModalForms: { [key in Modal]: string } = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  RESET: 'RESET',
  CLOSE: 'CLOSE',
  CHECK_EMAIL: 'CHECK_EMAIL',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  CREATE_NEW_ACCOUNT: 'CREATE_NEW_ACCOUNT',
  CREATE_NEW_PASSWORD: 'CREATE_NEW_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_CHANGED: 'UPDATE_PASSWORD_CHANGED',
};
