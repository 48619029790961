import React, { FunctionComponent, useState, useEffect } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AirplacerIcon, CloseIcon } from 'assets/svgs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import { useStyles } from './Modal.styles';
import { ModalProps } from './Modal.props';
// import MenuIcon from "@material-ui/core/SvgIcon/SvgIcon";

const ModalView: FunctionComponent<ModalProps> = props => {
  const [open, setOpen] = useState(props.open);
  const classes = useStyles(props);
  const theme = useTheme();
  // @ts-ignore
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeModal = () => {
    if (typeof props.onClose === 'function') {
      props.onClose(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [setOpen, props.open]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Hidden only={['md', 'lg', 'xl']}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={props.goToHomeRoute}
            >
              <AirplacerIcon />
            </IconButton>
          </Hidden>
          <div className={classes.spacer} />
          <CloseIcon className={classes.closeIconSmall} onClick={closeModal} />
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.contentContainer}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ModalView);
