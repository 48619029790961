import React, { FC } from 'react';
import View from './EntityDetailsCard.view';
import { EntityDetailsCardPublicProps } from './EntityDetailsCard.props';

const fields2 = [
  { title: 'Date', value: '' },
  { title: 'Time', value: '' },
  { title: 'Duration', value: '' },
];

const SelectContainer: FC<EntityDetailsCardPublicProps> = ({
  fields,
  ...props
}) => <View fields={fields || fields2} {...props} />;

export default React.memo(SelectContainer);
