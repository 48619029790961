import React, { FC, useState, useEffect } from 'react';
import { Modal as ModalComponent } from 'components/primitives';
import { LoginModalProps } from './LoginModal.props';

import LoginForm from '../LoginForm';
import CreateAccountForm from '../CreateAccountForm';
import CreateNewPasswordForm from '../CreateNewPasswordForm';
import UpdatePasswordForm from '../UpdatePasswordForm';
import SignUpForm from '../SignUpForm';
import ResetPasswordForm from '../ResetPasswordForm';
import PasswordChangedDialog from '../PasswordChangedDialog';
import UpdatePasswordChangedDialog from '../UpdatePasswordChangedDialog';
import CheckYourEmailDialog from '../CheckYourEmailDialog';
import { LoginModalForms } from './LoginModal.const';

const SignUpFormView: FC<LoginModalProps> = props => {
  const [modal, setModal] = useState(props.open);
  const onModalClose = () => {
    setModal(LoginModalForms.CLOSE);
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
  };
  const [params, setParams] = useState({});
  const openModal = (modalForm: string, opt: object = {}) => () => {
    setParams(opt);
    setModal(modalForm);
  };
  useEffect(() => {
    setModal(props.open);
  }, [setModal, props.open]);

  return (
    <ModalComponent
      open={modal !== LoginModalForms.CLOSE}
      onClose={onModalClose}
    >
      {modal === LoginModalForms.LOGIN && (
        <LoginForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.SIGNUP && (
        <SignUpForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.CREATE_NEW_ACCOUNT && (
        <CreateAccountForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.RESET && (
        <ResetPasswordForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.CREATE_NEW_PASSWORD && (
        <CreateNewPasswordForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.UPDATE_PASSWORD && (
        <UpdatePasswordForm openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.PASSWORD_CHANGED && (
        <PasswordChangedDialog openModal={openModal} onClose={onModalClose} />
      )}
      {modal === LoginModalForms.UPDATE_PASSWORD_CHANGED && (
        <UpdatePasswordChangedDialog
          openModal={openModal}
          onClose={onModalClose}
        />
      )}
      {modal === LoginModalForms.CHECK_EMAIL && (
        <CheckYourEmailDialog
          openModal={openModal}
          onClose={onModalClose}
          params={params}
        />
      )}
    </ModalComponent>
  );
};

export default React.memo(SignUpFormView);
