import { colors, fontFamilies } from 'themeConfig/themeConstants';

export default {
  root: {
    height: '30px',
    marginTop: '22px',
    width: 'calc(100% - 28px)',
    marginLeft: '14px',
    marginRight: '14px',
  },
  valueLabel: {
    fontFamily: fontFamilies.gilroy,
    fontSize: '15px',
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: colors.lightGrey,
    },
  },
  thumb: {
    height: '30px',
    width: '30px',
    marginTop: '-14px',
    marginLeft: '-14px',
    marginRight: '14px',
    color: colors.white,
    border: '1px solid rgb(160,170,173, 0.2)',
    borderRadius: '5px',
    '&:focus,&:hover,&$active': {
      boxShadow: '0px 2px 3px 1px rgba(0,0,0, 0.02)',
    },
  },
  track: {
    height: '3px',
    color: colors.blue,
  },
  rail: {
    color: colors.lightGrey,
  },
};
