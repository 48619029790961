import { fontFamilies } from 'themeConfig/themeConstants';

export default {
  root: {},
  input: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: fontFamilies.gilroy,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '1em',
  },
};
