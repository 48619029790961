import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { EmailIcon, LockIcon, UserIcon } from 'assets/svgs';
import { useStyles } from './CreateAccountForm.styles';
import { CreateAccountFormProps } from './CreateAccountForm.props';
import { Typo, Button, InputField, Link } from '../../primitives';
import { LoginModalForms } from '../LoginModal/LoginModal.const';

const ResetPasswordFormView: FC<CreateAccountFormProps> = props => {
  const classes = useStyles();
  const onLoginClick = props.openModal
    ? props.openModal(LoginModalForms.LOGIN)
    : () => {};

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Create Account
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        Enter your details to get started
      </Typo>
      {props.errors.length ? (
        <div className={classes.errorMessage}>
          {props.errors.map(msg => (
            <Typo variant="body1" color="red" key={msg}>
              {msg}
            </Typo>
          ))}
        </div>
      ) : null}
      <InputField
        icon={UserIcon}
        id="fullName"
        placeholder="Full Name"
        className={classes.field}
        value={props.fullName.value}
        error={Boolean(props.fullName.error) && props.fullName.touched}
        onChange={event => props.setFullName(event.target.value)}
        onBlur={props.setTouchedFullName}
      />
      <InputField
        icon={EmailIcon}
        id="email"
        placeholder="Email Address"
        className={classes.field}
        value={props.emailAddress.value}
        error={Boolean(props.emailAddress.error) && props.emailAddress.touched}
        onChange={event => props.setEmailAddress(event.target.value)}
        onBlur={props.setTouchedEmailAddress}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="password"
        placeholder="Password"
        className={classes.field}
        value={props.password.value}
        error={Boolean(props.password.error) && props.password.touched}
        onChange={event => props.setPassword(event.target.value)}
        onBlur={props.setTouchedPassword}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="confirmPassword"
        placeholder="Confirm Password"
        className={classes.field}
        value={props.confirmPassword.value}
        error={
          Boolean(props.confirmPassword.error) && props.confirmPassword.touched
        }
        onChange={event => props.setConfirmPassword(event.target.value)}
        onBlur={props.setTouchedConfirmPassword}
      />
      <Button
        text="Create Account"
        className={classes.button}
        onClick={props.onCreateAccount}
        disabled={props.disabled}
      />
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          Already have an account?&nbsp;
          <Typo
            variant="body1"
            color="blue"
            component="a"
            onClick={onLoginClick}
          >
            Log in
          </Typo>
        </Typo>
      </Box>
      <Box className={classes.footer}>
        <Typo variant="body1" color="lightGrey">
          By continuing you agree to our&nbsp;
          <Link onClick={props.toTerms}>
            <Typo variant="body1" color="red" component="span">
              T&Cs&nbsp;
            </Typo>
          </Link>
          and&nbsp;
          <Link onClick={props.toPrivacy}>
            <Typo variant="body1" color="red" component="span">
              Privacy Policy
            </Typo>
          </Link>
        </Typo>
      </Box>
    </>
  );
};

export default React.memo(ResetPasswordFormView);
