import { authActions, userActions } from 'store/actions';
import { Dispatch } from 'redux';
import {
  getProfileEndpoint,
  signupEndpoint,
} from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';

interface AccountCredentials {
  fullName: string;
  email: string;
  password?: string;
}

interface Data {}

interface Signalling {
  finishUpdating?: (data: any) => void;
  updateErrorMessage?: (message: any) => void;
}

const createAccountThunk = async (
  credentials: AccountCredentials,
  dispatch: Dispatch,
  getState: () => Store,
  signalling?: Signalling,
) => {
  // @ts-ignore
  try {
    const { signUp }: any = await apiFetch<AccountCredentials, User>(
      signupEndpoint,
      credentials,
    )(dispatch, getState).then(response => {
      dispatch(
        userActions.getProfile(
          apiFetch<Data, any>(getProfileEndpoint, {})(dispatch, getState).then(
            p => p.data,
          ),
        ),
      );
      return { signUp: response };
    });
    if (signalling && signalling.finishUpdating)
      signalling.finishUpdating(signUp);
    dispatch(authActions.signup(Promise.resolve(signUp)));
  } catch (error) {
    if (signalling && signalling.updateErrorMessage)
      signalling.updateErrorMessage(error);
    dispatch(authActions.signup(Promise.reject(error)));
  }
};

const createAccount = (
  credentials: AccountCredentials,
  signalling?: Signalling,
) => (dispatch: Dispatch, getState: () => Store) => {
  createAccountThunk(credentials, dispatch, getState, signalling);
};

export default createAccount;
