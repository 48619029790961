import React, { FC } from 'react';
import { Link } from '@material-ui/core';
import { useStyles } from './Link.styles';
import { LinkProps } from './Link.props';

const LinkView: FC<LinkProps> = (props: LinkProps) => {
  const classes = useStyles();
  const additionalProps = props.style
    ? {
        style: props.style,
      }
    : {};
  return (
    <Link
      color="inherit"
      onClick={props.onClick}
      className={classes.link}
      {...additionalProps}
    >
      {props.children}
    </Link>
  );
};

export default React.memo(LinkView);
