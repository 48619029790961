import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './EntityDetailsCard.styles';
import { EntityDetailsCardProps } from './EntityDetailsCard.props';
import Typo from '../Typo';
import HostedBy from '../../modules/HostedBy';
import HorizontalLine from '../HorizontalLine';

const EntityDetailsCardView: FC<EntityDetailsCardProps> = ({
  image,
  title,
  address,
  weekdayPrice,
  weekendPrice,
  fields,
  hostedBy,
  goToHostDetails,
}) => {
  const classes = useStyles({ image });
  return (
    <>
      <div className={classes.container} style={{ paddingBottom: '4em' }}>
        <div className={classes.imageContainer}>
          <div className={classes.image} />
        </div>
        <Typo variant="h3" color="darkGrey" className={classes.title}>
          {title}
        </Typo>
        <Typo variant="body1" color="lightGrey" className={classes.address}>
          {address}
        </Typo>
        {weekdayPrice ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typo variant="body1" color="lightGrey">
              Weekday price
            </Typo>
            <Typo variant="h5" color="darkGrey">
              ${weekdayPrice} (per hour)
            </Typo>
          </div>
        ) : (
          ''
        )}
        {weekendPrice ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typo variant="body1" color="lightGrey">
              Weekend price
            </Typo>
            <Typo variant="h5" color="darkGrey">
              ${weekendPrice} (per hour)
            </Typo>
          </div>
        ) : (
          ''
        )}
      </div>
      {hostedBy ? (
        <HostedBy host={{ name: hostedBy }} onClick={goToHostDetails} />
      ) : null}
      <div style={{ height: '1em', paddingTop: '1em' }}>
        <HorizontalLine />
      </div>
      <div className={classes.container} style={{ paddingTop: '1em' }}>
        <Typo variant="h4" color="darkGrey" className={classes.title}>
          Booking Details
        </Typo>
        <Grid container>
          {fields.map((field, i) => (
            <Grid
              item
              key={i}
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '1em',
              }}
            >
              <Typo variant="body1" color="lightGrey">
                {field.title}
              </Typo>
              <Typo variant="body1" color="darkGrey">
                {field.value}
              </Typo>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(EntityDetailsCardView);
