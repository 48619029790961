import { handleActions } from 'redux-actions';
import { presetReducers } from 'utils/Redux';
import { FilterState } from 'types/store/FilterState';
import { filterActions, authActions } from '../actions';

const DEFAULT_STATE: FilterState = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<FilterState, any>(
  {
    [String(filterActions.setFilter)]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
    [String(filterActions.clearFilter)]: () => {
      return DEFAULT_STATE;
    },
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
