import { Dispatch } from 'redux';
import { Store } from 'types/store/Store';
import AppErr from 'utils/App/AppErr/AppErr';
import { tokenActions } from '../../store/actions';
import { Endpoint, methods, auth } from './apiEndpoints';

const DEFAULT_HEADERS: Record<string, string> = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': 'true',
};

const buildRequest = (
  method: string,
  body?: {},
  extraHeaders: {} = {},
): RequestInit => {
  const request = {
    method,
    headers: {
      ...DEFAULT_HEADERS,
      ...extraHeaders,
    },
  };
  return body ? { ...request, body: JSON.stringify(body) } : { ...request };
};

// The data can look like anything depending on the query.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createQueryString = (data: any) =>
  Object.keys(data)
    .map(key => `${key}=${data[key]}`)
    .join('&');

const requestMethods = {
  GET: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> =>
    fetch(
      // eslint-disable-next-line no-nested-ternary
      urlTransformer
        ? urlTransformer(payload)
        : payload && Object.keys(payload).length > 0
        ? `${url}?${createQueryString(payload)}`
        : url,
      buildRequest(methods.GET, undefined, extraHeaders),
    ),
  PUT: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> =>
    fetch(
      urlTransformer ? urlTransformer(payload) : url,
      buildRequest(methods.PUT, payload, extraHeaders),
    ),
  POST: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> =>
    fetch(
      urlTransformer ? urlTransformer(payload) : url,
      buildRequest(methods.POST, payload, extraHeaders),
    ),
  DELETE: <PayloadType extends {}>(
    url: string,
    payload: PayloadType,
    extraHeaders: {},
    urlTransformer?: (data: any) => string,
  ): Promise<Response> =>
    fetch(
      // eslint-disable-next-line no-nested-ternary
      urlTransformer
        ? urlTransformer(payload)
        : payload && Object.keys(payload).length > 0
        ? `${url}?${createQueryString(payload)}`
        : url,
      buildRequest(methods.DELETE, undefined, extraHeaders),
    ),
};

const apiRequest = <PayloadType extends {}, ResponseType extends {}>(
  endpoint: Endpoint,
  payload: PayloadType,
  extraHeaders: {} = {},
): Promise<ResponseType> => {
  // @ts-ignore
  return requestMethods[endpoint.method](
    endpoint.url,
    payload,
    extraHeaders,
    endpoint.urlTransformer,
  ).then(async (resp: Response) => {
    const response = (await resp.json()) as ResponseType;
    if (!resp.ok) {
      throw response;
    }
    return response;
  });
};

interface RespWToken {
  token?: string;
}

export default <PayloadType extends {}, SuccessPayload>(
  endpoint: Endpoint,
  payload: PayloadType,
) => (dispatch: Dispatch, getState: () => Store): Promise<SuccessPayload> => {
  let extraHeaders: HeadersInit = {};
  if (endpoint.auth === auth.REQUIRED) {
    const { token } = getState();
    if (!token)
      return Promise.reject(new AppErr('Token not set for authedFetch'));
    extraHeaders = {
      Authorization: `Bearer ${token || ''}`,
    };
  } else if (endpoint.auth === auth.OPTIONAL) {
    const { token } = getState();
    if (token) {
      extraHeaders = {
        Authorization: `Bearer ${token || ''}`,
      };
    }
  }
  // @ts-ignore
  return apiRequest(endpoint, payload, extraHeaders)
    .then(async (response: unknown) => {
      if ((response as RespWToken).token !== undefined) {
        dispatch(
          tokenActions.updated((response as RespWToken).token as string),
        );
      }
      return response as SuccessPayload;
    })
    .catch(error => {
      throw error;
    });
};
