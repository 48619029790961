import React, { FC } from 'react';
import { Checkbox } from '@material-ui/core';
import { useStyles } from './Checkbox.styles';
import { CheckboxProps } from './Checkbox.props';

const CheckboxView: FC<CheckboxProps> = props => {
  const classes = useStyles();

  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      className={classes.checkbox}
      {...props}
    />
  );
};

export default React.memo(CheckboxView);
