import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';
import { LocationCardPublicProps } from './LocationCard.props';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: '7.5px',
      paddingRight: '7.5px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
    },
    imageContainer: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '75.24752475%',
      background: colors.lightGrey,
      position: 'relative',
      borderRadius: '10px',
      marginBottom: '10px',
    },
    image: ({ image }: LocationCardPublicProps) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url('${image}')`,
      backgroundSize: 'cover',
    }),
    title: {
      marginBottom: '10px',
      cursor: 'pointer !important',
      pointerEvents: 'auto',
    },
    address: {
      marginBottom: '10px',
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    bottomContainer: {
      display: 'flex',
    },
    price: {
      marginLeft: '10px',
    },
    heart: ({ favourite }: LocationCardPublicProps) =>
      favourite
        ? {
            position: 'absolute',
            right: '10px',
            top: '10px',
            transition: 'all 250ms ease-in-out',
            '&:hover': {
              transform: 'scale(1.2)',
              '& #Heart': {
                stroke: colors.white,
                fill: 'transparent',
              },
            },
            '& #Heart': {
              stroke: colors.white,
              fill: colors.blue,
            },
          }
        : {
            position: 'absolute',
            right: '10px',
            top: '10px',
            transition: 'all 250ms ease-in-out',
            '&:hover': {
              '& #Heart': {
                stroke: colors.white,
                fill: colors.blue,
              },
            },
            '& #Heart': {
              stroke: colors.white,
              fill: 'transparent',
            },
          },
  }),
);
