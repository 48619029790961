import { API } from '../../consts';

const { URL, VERSION } = API;

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const endpointGroups = {
  AUTH: 'auth',
  CONTACT: 'contact',
  ENTITY: 'entity',
  USER: 'user',
  BOOKING: 'booking',
  HELP_DESKS: 'helpdesks',
  MESSAGING: 'messaging',
  REVIEW: 'review',
  HOST: 'host',
  AVAILABILITIES: 'availabilities',
};

export const auth = {
  NOT_REQUIRED: 'NOT_REQUIRED',
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
};

export interface Endpoint {
  url: string;
  method: string;
  auth: string;
  urlTransformer?: (data: any) => string;
}

export const facebookAccessEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/facebook-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const googleAccessEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/google-access`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const signupEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/signup`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const loginEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/login`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const forgotPasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/forgot-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const resetPasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/reset-password`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const contactUsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.CONTACT}/contact-us`,
  method: methods.POST,
  auth: auth.OPTIONAL,
};

export const getProfileEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const updateProfileEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/profile`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const addFavouriteEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourite`,
  method: methods.POST,
  auth: auth.REQUIRED,
  urlTransformer: ({ entityId }) =>
    `${URL}/${VERSION}/${endpointGroups.USER}/favourite/${entityId}`,
};

export const removeFavouriteEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourite`,
  method: methods.DELETE,
  auth: auth.REQUIRED,
  urlTransformer: ({ entityId }) =>
    `${URL}/${VERSION}/${endpointGroups.USER}/favourite/${entityId}`,
};

export const getFavouritesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/favourites`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const sendVerificationEmailEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/send-verification-email`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const updatePasswordEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AUTH}/update-password`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getEntityByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ entityId }) =>
    `${URL}/${VERSION}/${endpointGroups.ENTITY}/${entityId}`,
};

export const getEntityFilters: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}/filters`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
};

export const getBookingByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ bookingId }) =>
    `${URL}/${VERSION}/${endpointGroups.BOOKING}/${bookingId}`,
};

export const addBookingEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const validateDiscountCodeEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/validate-discount-code`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getClientSecretEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/client-secret`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const getBookingsByUserIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.BOOKING}/my-bookings`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ hostId }) =>
    hostId
      ? `${URL}/${VERSION}/${
          endpointGroups.BOOKING
        }/my-bookings?hostId=${hostId}`
      : `${URL}/${VERSION}/${endpointGroups.BOOKING}/my-bookings`,
};

export const getAllHelpDesksEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.HELP_DESKS}`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const inviteAFriendEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.USER}/invite-a-friend`,
  method: methods.POST,
  auth: auth.NOT_REQUIRED,
};

export const getThreadByIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ threadId }) =>
    `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/${threadId}`,
};

export const getThreadsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.GET,
  auth: auth.REQUIRED,
};

export const sendMessageEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const createReviewEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.REVIEW}`,
  method: methods.POST,
  auth: auth.REQUIRED,
};

export const getReviewsEndpoint: Endpoint = {
  url: `${URL}/${VERSION}${endpointGroups.REVIEW}`,
  method: methods.GET,
  auth: auth.REQUIRED,
  urlTransformer: ({ entityId }) =>
    `${URL}/${VERSION}/${endpointGroups.REVIEW}?entityId=${entityId}`,
};

export const getHostEndpoint: Endpoint = {
  url: `${URL}/${VERSION}${endpointGroups.HOST}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ hostId }) =>
    `${URL}/${VERSION}/${endpointGroups.HOST}/${hostId}`,
};

export const readThreadEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread`,
  method: methods.PUT,
  auth: auth.REQUIRED,
  urlTransformer: ({ threadId }) =>
    `${URL}/${VERSION}/${endpointGroups.MESSAGING}/thread/${threadId}`,
};

export const getAvailabilitiesByEntityIdEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.AVAILABILITIES}`,
  method: methods.GET,
  auth: auth.NOT_REQUIRED,
  urlTransformer: ({ entityId, startDate, endDate }) =>
    `${URL}/${VERSION}/${
      endpointGroups.AVAILABILITIES
    }/${entityId}?startDate=${startDate}&endDate=${endDate}`,
};

export const getAllEntitiesEndpoint: Endpoint = {
  url: `${URL}/${VERSION}/${endpointGroups.ENTITY}`,
  method: methods.GET,
  auth: auth.OPTIONAL,
  urlTransformer: ({
    capacity,
    priceRange,
    dateRange,
    facilities,
    types,
    subtypes,
    location,
  }) => {
    let url = `${URL}/${VERSION}/${endpointGroups.ENTITY}`;
    if (
      !(
        capacity ||
        priceRange ||
        dateRange ||
        facilities ||
        types ||
        subtypes ||
        location
      )
    )
      return url;
    url += '?';
    const params = [];
    const reducer = (accumulator: string, currentValue: string) =>
      accumulator ? `${accumulator}&${currentValue}` : currentValue;
    if (capacity && capacity.min && capacity.max) {
      params.push(
        `capacity[min]=${capacity.min}&capacity[max]=${capacity.max}`,
      );
    }
    if (priceRange && priceRange.max) {
      params.push(
        `meta[price][min]=${priceRange.min || 0}&meta[price][max]=${
          priceRange.max
        }`,
      );
    }
    if (dateRange && dateRange.from && dateRange.to)
      params.push(
        `date[from]=${dateRange.from}&date[to]=${dateRange.to}&date[and]=true`,
      );
    if (facilities)
      params.push(
        facilities
          .map((facility: string) => `meta[facilities][]=${facility}`)
          .reduce(reducer, ''),
      );
    if (types)
      params.push(
        types
          .map((type: string) => `meta[types][]=${type}`)
          .reduce(reducer, ''),
      );
    if (subtypes)
      params.push(
        subtypes
          .map((subtype: string) => `meta[subtypes][]=${subtype}`)
          .reduce(reducer, ''),
      );
    if (location)
      params.push(
        `meta[location][long]=${location.long}&meta[location][lat]=${
          location.lat
        }`,
      );
    return `${url}${params.join('&')}`;
  },
};
