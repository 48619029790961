import React, { FC } from 'react';
import { LockIcon } from 'assets/svgs';
import { useStyles } from './UpdatePasswordForm.styles';
import { UpdatePasswordFormProps } from './UpdatePasswordForm.props';
import { Typo, Button, InputField } from '../../primitives';

const UpdatePasswordFormView: FC<UpdatePasswordFormProps> = props => {
  const classes = useStyles();
  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Update your password
      </Typo>
      <Typo variant="body2" className={classes.subHeading} color="lightGrey">
        Update your password below
      </Typo>
      <InputField
        icon={LockIcon}
        type="password"
        id="currentPassword"
        placeholder="Current Password"
        className={classes.field}
        value={props.currentPassword.value}
        error={
          Boolean(props.currentPassword.error) && props.currentPassword.touched
        }
        onChange={event => props.setCurrentPassword(event.target.value)}
        onBlur={props.setTouchedCurrentPassword}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="password"
        placeholder="Password"
        className={classes.field}
        value={props.newPassword.value}
        error={Boolean(props.newPassword.error) && props.newPassword.touched}
        onChange={event => props.setNewPassword(event.target.value)}
        onBlur={props.setTouchedNewPassword}
      />
      <InputField
        icon={LockIcon}
        type="password"
        id="confirmPassword"
        placeholder="Confirm Password"
        className={classes.field}
        value={props.verifyNewPassword.value}
        error={
          Boolean(props.verifyNewPassword.error) &&
          props.verifyNewPassword.touched
        }
        onChange={event => props.setVerifyNewPassword(event.target.value)}
        onBlur={props.setTouchedVerifyNewPassword}
      />
      <Button
        text="Confirm New Password"
        disabled={props.disabled}
        className={classes.button}
        onClick={props.onClickCreateNewPassword}
      />
      {props.errors.length || props.error ? (
        <div className={classes.errorMessage}>
          {props.error && (
            <Typo variant="body1" color="red">
              Something went wrong try again later.
            </Typo>
          )}
          {props.errors.map(msg => (
            <Typo variant="body1" color="red" key={msg}>
              {msg}
            </Typo>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default React.memo(UpdatePasswordFormView);
