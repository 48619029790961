import React, { FC } from 'react';
import { Divider } from '@material-ui/core';
import { useStyles } from './HorizontalLine.styles';
import { HorizontalLineProps } from './HorizontalLine.props';

const HorizontalLineView: FC<HorizontalLineProps> = (
  props: HorizontalLineProps,
) => {
  const classes = useStyles();
  const additionalProps = props.style ? { style: props.style } : {};
  return (
    <Divider
      className={`${classes.default} ${props.className}`}
      {...additionalProps}
    />
  );
};

export default React.memo(HorizontalLineView);
