import {
  SET_BOOKING,
  SET_BOOKING_QUESTIONS,
  SET_BOOKING_STATUS,
  CLEAR_BOOKING,
} from '../actions/bookingDetails';

const initialState = {
  booking: null,
};

const bookingDetailsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_BOOKING:
      return {
        booking: action.booking,
      };
    case SET_BOOKING_QUESTIONS:
      return {
        booking: {
          ...state.booking,
          questions: action.questions,
        },
      };
    case SET_BOOKING_STATUS:
      return {
        booking: {
          ...state.booking,
          status: action.status,
        },
      };
    case CLEAR_BOOKING:
      return initialState;
    default:
      return state;
  }
};

export default bookingDetailsReducer;
