import { authActions, userActions } from 'store/actions';
import { Dispatch } from 'redux';
import { getProfileEndpoint, loginEndpoint } from 'services/fetch/apiEndpoints';
import apiFetch from 'services/fetch/apiFetch';
import { User } from '../../types/User';
import { Store } from '../../types/store/Store';

interface Credentials {
  email: string;
  password?: string;
}

interface Data {}

const login = (credentials: Credentials) => (
  dispatch: Dispatch,
  getState: () => Store,
) => {
  dispatch(
    authActions.login(
      apiFetch<Credentials, User>(loginEndpoint, credentials)(
        dispatch,
        getState,
      ).then(u => {
        dispatch(
          userActions.getProfile(
            apiFetch<Data, any>(getProfileEndpoint, {})(
              dispatch,
              getState,
            ).then(p => p.data),
          ),
        );
        return u;
      }),
    ),
  );
};

export default login;
