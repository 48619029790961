import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { colors } from 'themeConfig/themeConstants';
import { TextAreaProps } from './TextArea.props';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: 1,
    },
    textArea: (props: TextAreaProps) => ({
      resize: 'none',
      display: 'flex',
      flex: 1,
      border: props.error
        ? `1px solid ${colors.red}`
        : '1px solid rgb(245, 246, 246)',
      borderRadius: '5px',
      padding: theme.spacing(2),
    }),
  }),
);
