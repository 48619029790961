import React, { FC } from 'react';
import { useStyles } from './UpdatePasswordChangedDialog.styles';
import { UpdatePasswordChangedDialogProps } from './UpdatePasswordChangedDialog.props';
import { Typo } from '../../primitives';

const UpdatePasswordChangedFormView: FC<
  UpdatePasswordChangedDialogProps
> = props => {
  const classes = useStyles();

  return (
    <>
      <Typo variant="h2" className={classes.heading}>
        Password Changed
      </Typo>
    </>
  );
};

export default React.memo(UpdatePasswordChangedFormView);
