import { makeStyles, createStyles } from '@material-ui/core/styles';
import { AvatarProps } from './Avatar.props';

export const useStyles = makeStyles(() =>
  createStyles({
    avatar: (props: AvatarProps) => ({
      margin: props.margin === undefined ? 10 : props.margin,
      width: props.size || 40,
      height: props.size || 40,
      borderRadius: props.square ? 5 : (props.size || 40) / 2,
    }),
  }),
);
