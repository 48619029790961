import { NOT_FOUND as RFR_NOT_FOUND } from 'redux-first-router';
import { RouteDef } from 'types/RouteDef';
import { copyObjKeys } from 'utils/Data';
import { makeAuthGuard } from '../components/core/AuthGuard';

interface RouteDefMap {
  [k: string]: RouteDef;
}

const ROUTES: RouteDefMap = copyObjKeys<RouteDef, RouteDefMap>(
  {
    ROOT: {
      component: 'Search',
      path: '/',
    },
    PROFILE: {
      component: 'Profile',
      path: '/profile',
    },
    EXAMPLE: {
      component: 'ExamplePage',
      path: '/eg',
      middleware: () =>
        makeAuthGuard({
          allowAuthed: true,
          redirectAnon: ROUTES.ROOT,
        }),
    },
    PRIVACY_POLICY: {
      component: 'PrivacyPolicy',
      path: '/privacy',
    },
    TERMS: {
      component: 'Terms',
      path: '/terms',
    },
    CONTACT_US: {
      component: 'ContactUs',
      path: '/contact-us',
    },
    SEARCH: {
      component: 'Search',
      path: '/search',
    },
    LISTING_DETAILS: {
      component: 'ListingDetails',
      path: '/listing/:id',
    },
    BOOKING_DETAILS: {
      component: 'BookingDetails',
      path: '/booking-details',
    },
    CONFIRM_BOOKING_DETAILS: {
      component: 'ConfirmBookingDetails',
      path: '/confirm-booking-details',
    },
    BOOKING_REQUESTED: {
      component: 'BookingRequested',
      path: '/booking-requested',
    },
    MY_BOOKINGS: {
      component: 'MyBookings',
      path: '/my-bookings',
    },
    VIEW_BOOKING_DETAILS: {
      component: 'ViewBookingDetails',
      path: '/view-booking-details/:id',
    },
    MY_BOOKING_HISTORY: {
      component: 'MyBookingHistory',
      path: '/my-booking-history',
    },
    REVIEW_BOOKING: {
      component: 'ReviewBooking',
      path: '/review-booking/:id',
    },
    INVITE_A_FRIEND: {
      component: 'InviteAFriend',
      path: '/invite-a-friend',
    },
    MY_SAVED_LISTINGS: {
      component: 'MySavedListings',
      path: '/my-saved-listings',
    },
    MESSAGES: {
      component: 'Messages',
      path: '/messages',
    },
    HOST_DETAILS: {
      component: 'HostDetails',
      path: '/host-details/:id',
    },
    NOT_FOUND: {
      component: 'NotFound',
      id: RFR_NOT_FOUND,
      path: '/404',
    },
  },
  'id',
);

export default ROUTES;
