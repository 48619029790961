import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from './Button.styles';
import { ButtonProps } from './Button.props';
import Typo from '../Typo';

const ButtonView: FC<ButtonProps> = (props: ButtonProps) => {
  const classes = useStyles(props);
  const {
    text,
    style,
    onClick,
    icon: Icon,
    className,
    type,
    loading = false,
    loadingText = '',
    disabled,
  } = props;

  return (
    // @ts-ignore
    <Button
      className={`${classes.button} ${className}`}
      style={style}
      onClick={onClick}
      // @ts-ignore
      type={type}
      disabled={disabled}
    >
      {Icon && <Icon className={classes.icon} />}
      {loading ? (
        <>
          {loadingText && (
            <Typo className={classes.typography} variant="h5">
              {loadingText}
            </Typo>
          )}
          <CircularProgress
            style={{ color: '#fff', marginLeft: 16 }}
            size={24}
          />
        </>
      ) : (
        <Typo className={classes.typography} variant="h5">
          {text}
        </Typo>
      )}
    </Button>
  );
};

export default React.memo(ButtonView);
