import { handleActions } from 'redux-actions';
import { asyncData, createAsyncReducers, presetReducers } from 'utils/Redux';
import { EntityState } from 'types/store/EntityState';
import { ASYNC_STATUS } from 'types/store/AsyncStatus';
import { authActions } from '../actions';
import { ExampleListing } from '../../types/ExampleListing';

const DEFAULT_STATE: EntityState = asyncData(ASYNC_STATUS.INITIAL);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = handleActions<EntityState, any>(
  {
    ...createAsyncReducers<ExampleListing[]>('ENTITY/ENTITY'),
    [String(authActions.logout)]: presetReducers.makeReset(DEFAULT_STATE),
  },
  DEFAULT_STATE,
);

export default reducer;
