// import MomentUtils from '@date-io/moment';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC } from 'react';
import { CalendarPublicProps } from './Calendar.props';
import View from './Calendar.view';

const Container: FC<CalendarPublicProps> = (props: CalendarPublicProps) => (
  // <MuiPickersUtilsProvider utils={MomentUtils}>
  <View {...props} />
  // </MuiPickersUtilsProvider>
);

export default React.memo(Container);
